import React, { useState, useEffect, useRef, useCallback } from "react";
import { createClient } from "@supabase/supabase-js";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  useNavigate,
} from "react-router-dom";
import { Input } from "./components/ui/input";
import { Button } from "./components/ui/button";
import { Card, CardContent } from "./components/ui/card";
import { Textarea } from "./components/ui/textarea";
import { useToast } from "./components/ui/use-toast";
import { Toaster } from "./components/ui/toaster";
import { Loader2, ArrowLeft, Clock, Trash, Send } from "lucide-react";
import { Slider } from "./components/ui/slider";

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const NoteEditor = () => {
  const { noteId } = useParams();
  const navigate = useNavigate();
  const [noteContent, setNoteContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [expirationTime, setExpirationTime] = useState(15);
  const [expiresAt, setExpiresAt] = useState(null);
  const { toast } = useToast();

  const textareaRef = useRef(null);
  const saveTimeoutRef = useRef(null);

  useEffect(() => {
    if (noteId) {
      loadNote(noteId);
    }
  }, [noteId]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(checkExpiration, 60000); // Check every minute
    return () => clearInterval(timer);
  }, [expiresAt]);

  const loadNote = async (id) => {
    setIsLoading(true);
    try {
      console.log("Loading note with ID:", id);
      const { data, error } = await supabase
        .from("notes")
        .select("note_id, content, expires_at")
        .eq("note_id", id)
        .single();

      if (error) {
        if (error.code === "PGRST116") {
          console.log("Note not found, creating new note");
          handleNewNote(id);
        } else {
          throw error;
        }
      } else {
        console.log("Note loaded:", data);
        const now = new Date();
        const noteExpiresAt = new Date(data.expires_at);

        if (noteExpiresAt <= now) {
          await handleExpiredNote(id);
        } else {
          setNoteContent(data.content);
          setExpiresAt(noteExpiresAt);
          const minutesLeft = Math.round((noteExpiresAt - now) / 60000);
          setExpirationTime(minutesLeft > 0 ? minutesLeft : 1);
          toast({
            title: "Note Loaded",
            description: `Temporary note loaded successfully. It will expire in ${minutesLeft} minutes.`,
          });
        }
      }
    } catch (error) {
      console.error("Error opening note:", error);
      toast({
        title: "Error",
        description: "Failed to open note. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewNote = (id) => {
    setNoteContent("");
    setExpiresAt(null);
    toast({
      title: "New Note",
      description: `Creating a new temporary note that will expire in ${expirationTime} minutes.`,
    });
  };

  const handleExpiredNote = async (id) => {
    toast({
      title: "Note Expired",
      description: "This note has expired and will be deleted.",
      variant: "destructive",
    });
    await deleteNote(id);
    handleNewNote(id);
  };

  const deleteNote = async (id) => {
    setIsLoading(true);
    try {
      console.log("Attempting to delete note with note_id:", id);

      const { data, error } = await supabase
        .from("notes")
        .delete()
        .eq("note_id", id);

      if (error) {
        console.error("Supabase delete error:", error);
        throw error;
      }

      console.log("Delete operation completed", data);

      toast({
        title: "Note Deleted",
        description: "The note has been successfully deleted.",
      });

      navigate("/");
    } catch (error) {
      console.error("Error in delete operation:", error);
      toast({
        title: "Error",
        description: "Failed to delete note. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const saveNote = useCallback(async () => {
    if (!noteId.trim()) {
      console.error("Note ID is required");
      return;
    }

    try {
      console.log("Autosaving note:", { noteId, noteContent, expirationTime });

      const newExpiresAt = new Date(Date.now() + expirationTime * 60000);
      const { data, error } = await supabase.from("notes").upsert(
        {
          note_id: noteId,
          content: noteContent,
          expires_at: newExpiresAt.toISOString(),
        },
        { onConflict: "note_id", returning: "minimal" }
      );

      if (error) throw error;

      console.log("Autosave completed", data);
      setExpiresAt(newExpiresAt);
    } catch (error) {
      console.error("Error autosaving note:", error);
      toast({
        title: "Autosave Error",
        description: "Failed to save note. Please try again.",
        variant: "destructive",
      });
    }
  }, [noteId, noteContent, expirationTime, toast]);

  useEffect(() => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    saveTimeoutRef.current = setTimeout(() => {
      saveNote();
    }, 2000); // Autosave 2 seconds after the last change

    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, [noteContent, saveNote]);

  const handleBack = () => {
    navigate("/");
  };

  const checkExpiration = () => {
    if (expiresAt && new Date() > expiresAt) {
      handleExpiredNote(noteId);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gradient-to-r from-green-50 to-blue-50">
      <div className="flex flex-col sm:flex-row justify-between items-center p-4 bg-white shadow-md">
        <Button
          onClick={handleBack}
          variant="ghost"
          className="text-green-600 mb-2 sm:mb-0"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Back
        </Button>
        <h1 className="text-xl font-semibold text-gray-800 flex items-center mb-2 sm:mb-0">
          🐨
          <span className="ml-2">Note: {noteId}</span>
        </h1>
        <div className="flex space-x-2">
          <Button
            onClick={() => deleteNote(noteId)}
            className="bg-red-500 hover:bg-red-600 text-white"
          >
            <Trash className="h-4 w-4" />
            <span className="hidden sm:inline ml-2">Delete</span>
          </Button>
        </div>
      </div>
      <div className="flex-grow p-4 relative">
        <Textarea
          ref={textareaRef}
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          className="w-full h-full text-base sm:text-lg p-4 sm:p-6 rounded-lg shadow-inner focus:ring-2 focus:ring-green-300 resize-none bg-white"
          placeholder="Start typing your temporary note here..."
          style={{
            backgroundImage: "linear-gradient(#E6F2E9 1px, transparent 1px)",
            backgroundSize: "100% 2.3rem",
            lineHeight: "2.3rem",
            height: "calc(100vh - 120px)", // Adjust this value based on your header height
            padding: "5px",
          }}
        />
        <div className="absolute bottom-4 right-4 text-gray-400 pointer-events-none">
          <Clock className="h-4 w-4 sm:h-6 sm:w-6 inline mr-1" />
          <span className="text-xs sm:text-sm">
            {expiresAt
              ? `Expires: ${expiresAt.toLocaleString()}`
              : `${expirationTime} min`}
          </span>
        </div>
      </div>
    </div>
  );
};

const HomePage = () => {
  const [noteId, setNoteId] = useState("");
  const [expirationTime, setExpirationTime] = useState(15);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (noteId.trim()) {
      navigate(`/${noteId}`);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen p-4 bg-gradient-to-r from-green-100 to-blue-100">
      <Card className="w-full max-w-md shadow-lg bg-white rounded-2xl">
        <CardContent className="p-4 sm:p-6">
          <div className="flex items-center justify-center mb-6">
            <h1 className="text-2xl sm:text-3xl font-bold text-center ml-2 text-gray-800">
              🐨 KoalaShare
            </h1>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="relative">
              <Input
                ref={inputRef}
                type="text"
                value={noteId}
                onChange={(e) => setNoteId(e.target.value)}
                placeholder="Enter note ID (new or existing)"
                className="text-base sm:text-lg pr-10 border-2 border-green-300 focus:border-green-500 rounded-full"
              />
              <Button
                type="submit"
                className="absolute right-0 top-1/2 transform -translate-y-1/2 rounded-full p-2 bg-green-500 hover:bg-green-600"
              >
                <Send className="h-4 w-4 sm:h-6 sm:w-6" />
              </Button>
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Expiration time: {expirationTime} minutes
              </label>
              <Slider
                value={[expirationTime]}
                onValueChange={(value) => setExpirationTime(value[0])}
                min={1}
                max={60}
                step={1}
                className="w-full"
              />
            </div>
          </form>
          <p className="text-center mt-4 text-xs sm:text-sm text-gray-600">
            Enter a note ID to create or open a temporary note
          </p>
        </CardContent>
      </Card>
    </div>
  );
};

const App = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#E6F2E9"; // Light green background
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:noteId" element={<NoteEditor />} />
        </Routes>
      </div>
      <Toaster />
    </Router>
  );
};

export default App;
